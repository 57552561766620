import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_g = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - G'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>G</h2>
     <LatQuotesIntro />
     <p><b>Generale nihil certum implicat</b> - A general expression implies nothing certain.</p>
<p><b>Generalia praecedunt, specialia sequuntur</b> - Things general precede, things special follow.</p>
<p><b>Generalia specialibus non derogant</b> - Things general do not derogate from things special.</p>
<p><b>Generalis regula generaliter est intelligenda</b> - A general rule is to be generally understood.</p>
<p><b>Gravius est divinam quam temporalem laedere majestatem</b> - It is more serious to hurt divine than temporal majesty.</p>

   </Layout>
  )
}

export default LatQuotes_g
